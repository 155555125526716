
<template>
	<div class="ucLay radius">
	   <div class="ucCol_24 ucRow_24">			 
				<div class="ucCol_24 ucRow_2">
					<div style="padding-left: 50px; position: absolute; bottom: 0;">
					<el-form ref="searchForm"   label-width="240" :inline="true">
						 <el-form-item label="选择项目:">
							 <el-select v-model="projectId" placeholder="请选择" style="width:240px" clearable>
							   <el-option v-for="data in projectList" :key="data.plot_id" :label="data.name" :value="data.plot_id"></el-option>
							 </el-select>
						 </el-form-item>
						<el-form-item label="时间查询:">
						  <el-date-picker
						      v-model="selecttime"
						      type="daterange"
						      range-separator="至"
						      start-placeholder="开始日期"
						      end-placeholder="结束日期"
						      value-format="YYYY-MM-DD"
						  >
						  </el-date-picker>
						</el-form-item>
						  
					  <el-form-item><el-button type="primary" @click="searchForm()">搜索</el-button></el-form-item>
					 <el-form-item><el-button  type="primary" @click="exportExcel()">导出报事分析表</el-button></el-form-item>
					</el-form>
					</div>
				</div>
				<div class="ucCol_7 ucRow_16" style="padding-left: 50px;">
					<div class="ucCard" style="height: 90%;">
					        <div class="ucCard-header">
					            <div class="fl flName">报事数量</div>
					            <div class="fr frBtn">
					            </div>
					            <div class="clear"></div>
					        </div>
					        <div class="ucCard-content" v-if="isShowEcharts" style="height: 85%;" id="wvEcharts11"></div>
					 </div>
				</div>
				
				<div class="ucCol_7 ucRow_16" style="padding-left: 45px;">
					<div class="ucCard" style="height: 90%">
					        <div class="ucCard-header">
					            <div class="fl flName">报事人员类型统计</div>
					            <div class="fr frBtn">
					            </div>
					            <div class="clear"></div>
					        </div>
					        <div class="ucCard-content" v-if="isShowEcharts" style="height: 85%;"  id="wvEcharts33"></div>
					 </div>
				</div>
				<div class="ucCol_7 ucRow_16" style="padding-left: 45px;">
					<div class="ucCard" style="height: 90%">
					        <div class="ucCard-header">
					            <div class="fl flName">人员报事数量</div>
					            <div class="fr frBtn">
					            </div>
					            <div class="clear"></div>
					        </div>
					        <div class="ucCard-content" v-if="isShowEcharts" style="height: 85%;"  id="wvEcharts44"></div>
					 </div>
				</div>
				
				<div class="ucCol_7 ucRow_16" style="padding-left: 45px;">
					<div class="ucCard" style="height: 90%">
					        <div class="ucCard-header">
					            <div class="fl flName">项目自检统计</div>
					            <div class="fr frBtn">
					            </div>
					            <div class="clear"></div>
					        </div>
					        <div class="ucCard-content" v-if="isShowEcharts" style="height: 85%;"  id="thingType1"></div>
					 </div>
				</div>
				<div class="ucCol_7 ucRow_16" style="padding-left: 50px;">
					<div class="ucCard" style="height: 90%;">
					        <div class="ucCard-header">
					            <div class="fl flName">公司质检统计</div>
					            <div class="fr frBtn">
					            </div>
					            <div class="clear"></div>
					        </div>
					        <div class="ucCard-content" v-if="isShowEcharts" style="height: 85%;" id="thingType2"></div>
					 </div>
				</div>
				<div class="ucCol_7 ucRow_16" style="padding-left: 50px;">
					<div class="ucCard" style="height: 90%;">
					        <div class="ucCard-header">
					            <div class="fl flName">专项指导统计</div>
					            <div class="fr frBtn">
					            </div>
					            <div class="clear"></div>
					        </div>
					        <div class="ucCard-content" v-if="isShowEcharts" style="height: 85%;" id="thingType3"></div>
					 </div>
				</div>
				
		</div>
	</div>
</template>
<script>
//引入vue方法
import { ElConfigProvider } from 'element-plus';
//中文包
import zhCn from "element-plus/lib/locale/lang/zh-cn";

import * as echarts from 'echarts';
export default {

  name: 'ZhProvider',

  setup(){
    let locale = zhCn
    return{
      locale
    }
  },
	data() {
	  let self=this;
	  return {
		selecttime:[],
	    dataList:[],  //表格数据集
	    isShowEcharts: false,
	    tableDataName:'',   //综合查询条件参数
	    optionsype:'',     //所属项目选项
	    project:'',       //选择的所属项目
		content:'',
		status:'',
		memo:'',
		create_time:'',
		update_time:'',
		projectId:"",
		projectList:[],
		option :{
		  title: {
			text: ''
		  },
		  tooltip: {
			trigger: 'axis'
		  },
		  legend: {
			data: []
		  },
		  grid: {
			left: '3%',
			right: '4%',
			bottom: '3%',
			containLabel: true
		  },
		  toolbox: {
			feature: {
			  saveAsImage: {}
			}
		  },
		  xAxis: {
			type: 'category',
			boundaryGap: false,
			data: []
		  },
		  yAxis: {
			type: 'value'
		  },
		  series: [
			{
			  name: '',
			  type: 'line',
			  stack: 'Total',
			  data: []
			}
		  ]
		},
		option2 :{
		   title: {
		          text: '',
		          subtext: '',
		          left: 'center'
		      },
		      tooltip: {
		          trigger: 'item'
		      },
		      legend: {
		          
				  "icon": "circle",
				  "x": "center",
				  "y": "1%"
		      },
		      series: [
		          {
		              name: '',
		              type: 'pie',
		              radius: '50%',
		              data: [],
		              emphasis: {
		                  itemStyle: {
		                      shadowBlur: 10,
		                      shadowOffsetX: 0,
		                      shadowColor: 'rgba(0, 0, 0, 0.5)'
		                  }
		              }
		          }
		      ]
		},
		option3 :{
		   title: {
		          text: '',
		          subtext: '',
		          left: 'center'
		      },
		      tooltip: {
		          trigger: 'item'
		      },
		      legend: {
		          orient: 'vertical',
		          left: 'left',
		      },
		      series: [
		          {
		              name: '',
		              type: 'pie',
		              radius: '50%',
		              data: [],
		              emphasis: {
		                  itemStyle: {
		                      shadowBlur: 10,
		                      shadowOffsetX: 0,
		                      shadowColor: 'rgba(0, 0, 0, 0.5)'
		                  }
		              }
		          }
		      ]
		},
		option4 :{
		  title: {
			text: ''
		  },
		  tooltip: {
			trigger: 'axis'
		  },
		  legend: {
			data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine']
		  },
		  grid: {
			top: "15%",
			left: "5%",
			right: "3%",
			bottom: "5%",
			containLabel: true, //是否包含坐标轴的文字
		  },
		  toolbox: {
			feature: {
			  saveAsImage: {}
			}
		  },
		  xAxis: {
			type: 'value'
		  },
		  yAxis: {			
			type: 'category',
			data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
		  },
		  series: [
			{
			  name: '',
			  type: 'bar',
			  barWidth: 20, // 柱的宽度
			  stack: 'Total',
			  data: [120, 132, 101, 134, 90, 230, 210],
			label: {
				show: true, // 显示值
				position: "right", // 显示位置
				color: "white",
			  },
			  // 每一个条目的样式配置
			  itemStyle: {
				barBorderRadius: [0, 34, 34, 0], // 圆角
			  },

			}
		  ]
		}
	  }
	},

  /*启动后触发该方法*/
  created() {
	this.getEcharts1();
	this.getEchartsProList();	
  },
  methods: {
	  exportExcel(){

	  	if(this.selecttime != null && this.selecttime != ""){
	  		
	  	}else{
	  		this.$message("请选择筛选日期")
	  		return
	  	}
	  	
	  	var req = {};
	  	req.cmd = "exportRmsThingTotal";
	  	req.StartTime = this.selecttime[0];
		req.endTime = this.selecttime[1];
		req.plot_id = this.projectId;
	  	this.$sknet.download(this.$skconf("met.surl"), req);
	  },
	getEcharts1:function(){
        this.dataList = [];
        var req = {};
        req.cmd = "getRmsThingTotal";
		req.plot_id = this.projectId;
		this.isShowEcharts = true;
		req.selecttime = this.selecttime;
        this.$sknet.postJson(this.$skconf("brum.surl"), req, function(res) {
	      var echarts_x=res.datas.echarts_x_1;
	      var echarts_y=res.datas.echarts_y_1;
		  this.myEcharts(echarts_y,echarts_x);
		  this.myEcharts2(res.datas.dataList);
		  this.myEcharts3(res.datas.dataList3);
		  this.myEcharts4(res.datas.dataList4);		
        }, null, this);
    },
	searchForm:function(){
		this.getEcharts1();
	},
	getEchartsProList:function(){
	    this.dataList = [];
	    var req = {};
	    req.cmd = "getEchartsProList";
	    this.$sknet.postJson(this.$skconf("met.surl"), req, function(res) {
		  this.projectList = res.datas.projectList;
	    }, null, this);
	},
	myEcharts(series,Xseries){
		var items = this.echarts_ys;
		const myChart =echarts.init(document.getElementById('wvEcharts11'));
		var option = this.option;
		option.xAxis.data=Xseries;
		option.series[0].data = series;	
		option.legend.data=[];
		myChart.setOption(option);
	},
	myEcharts2(dataList){
		// const myChart =echarts.init(document.getElementById('wvEcharts22'));
		// var option = this.option2;
		// option.series[0].data = dataList;	
		// myChart.setOption(option);
		
		const myChart1 =echarts.init(document.getElementById('thingType1'));
		const myChart2 =echarts.init(document.getElementById('thingType2'));
		const myChart3 =echarts.init(document.getElementById('thingType3'));
		var option1 = Object.assign({}, this.option2);
		var option2 = Object.assign({}, this.option2);
		var option3 = Object.assign({}, this.option2);
		var data1 = [], data2 = [], data3 = [];
		if(typeof dataList != 'undefined' && dataList.length > 0){
			for(var i = 0 ; i < dataList.length; i++){
				if(dataList[i].name.indexOf("项目自检-") != -1){
					data1.push(dataList[i]);
				}else if(dataList[i].name.indexOf("公司质检-") != -1){
					data2.push(dataList[i]);
				}else if(dataList[i].name.indexOf("专项指导-") != -1){
					data3.push(dataList[i]);
				}else {
					
				}
			}

		}
		
		option1.series[0].data = data1;
		myChart1.setOption(option1);
		
		option2.series[0].data = data2;
		myChart2.setOption(option2);
		
		option3.series[0].data = data3;
		myChart3.setOption(option3);
		
		
	},
	myEcharts3(dataList){
		const myChart =echarts.init(document.getElementById('wvEcharts33'));
		var option = this.option3;
		option.series[0].data = dataList;		
		console.log(option);
		myChart.setOption(option);
	},
	myEcharts4(dataList){
		const myChart =echarts.init(document.getElementById('wvEcharts44'));
		var option = this.option4;	
		dataList = dataList.sort((a, b) => a.value - b.value);
		let barDataY = dataList.map((item) => item.name);
		let barDataX = dataList.map((item) => item.value);		
		option.yAxis.data=barDataY;
		option.series[0].data = barDataX;	
		console.log(option);
		myChart.setOption(option);
	}
  },
  components: {
    [ElConfigProvider.name]: ElConfigProvider,
  },
}
</script>